/* element styles */
html, body {
  box-sizing: border-box;
  color: #505050;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

label, .level-label {
  font-weight: 500;
}

a {
  color: #298fd1;
  font-family: Jost,Arial,Sans-serif;
  font-weight: 700;
  text-decoration: none;
}

a:focus, a:hover {
  color: #2AC288;
}

h2, h3 {
  display: block;
  font-family: Jost,Arial,Sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

h2 {
  color: #000;
  font-size: 18px;
  line-height: 20px;
}

h3 {
  color: #505050;
  font-size: 16px;
}

/* utility styles */
.hidden {
  display: none;
}


.caps {
    text-transform: uppercase;
    font-weight: 500;
}


.no-scroll {
  background-color: #505050;
  position: relative;
  overflow: hidden;
}

.hide-for-small {
  display: inherit;
}

/* Small devices (phones, 768px and down) */
@media only screen and (max-width: 768px) {
  .hide-for-small {
    display: none !important;
  }
}

.show-for-small {
  display: none;
}

@media only screen and (max-width: 768px) {
  .show-for-small {
    display: block;
  }
}

/* generic styles */
.action_button {
  background-color: #2AC288;
  border: none;
  border-radius: 6px;
  box-shadow: 4px 4px 4px 0 #666;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Jost,Arial,Sans-serif;
  font-size: 32px;
  font-weight: 300;
  padding: 10px;
}

@media only screen and (max-width: 768px) {
  .action_button {
    font-size: 16px;
  }
}

.action_button--map {
  background-color: #FBB800CC;
  box-shadow: none;
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

/* whole page container */
.container {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
}

/* if the landing page / instructions modal is up */
.no-scroll .container {
  opacity: 50%;
}

@media only screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }
}

p.desktop-text {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  color: #505050;
}

/* landing page / instructions text */
.instructions_overlay {
  position: absolute;
  top: 100px;
  bottom: 100px;
  left: 100px;
  right: 100px;
  background-position-y: 80%;
  background-size: cover;
  box-shadow: #333 0 0 10px;
  overflow: auto;
  z-index: 9;
}

@media only screen and (max-width: 768px) {
  .instructions_overlay {
    top: 20px;
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
}

.landing-header {
    background: linear-gradient(#fff0, 15%, #fff 80%),
        linear-gradient(to right, #00c3f7cc, #05d1cccc, #2ac288cc, #9bcd4dcc, #fbb800cc);
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

@media only screen and (max-width: 768px) {
  .landing-header {
    padding: 0;
  }
}

.header-item {
    padding: 20px;
    text-align: left;
}

@media only screen and (max-width: 768px) {
  .header-item {
    margin: 0 auto  }
}

.landing-logo {
    width: 100%;
}

.landing-welcome {
    color: #ccc;
    font-size: 92px;
    font-family: Jost,Arial,Sans-serif;
    font-weight: 300;

}

/* We only have this many breakpoints for the landing, because it is very special */
@media only screen and (min-width: 1000px) {
    .landing-welcome {
      font-size: 92px;
      margin-right: 60px;
      margin-top: -26px;
    }
}

@media only screen and (max-width: 999px) {
    .landing-welcome {
      font-size: 48px;
      margin-right: 30px;
      margin-top: -15px;
    }
}

.landing-container {
    background-image: url("../img/index_background.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    justify-content: space-between;
    overflow-y: auto;
    text-align: center;
}

.landing-intro {
    flex: 0 1 auto;
    font-family: Roboto Condensed,Arial,Sans-serif;
    font-size: 24px;
    font-weight: 500;
    margin: 0 auto;
    max-width: 1148px;
    margin-bottom: 20px;
    padding: 0 20px;
}

/* We only have this many breakpoints for the landing, because it is very special */
@media only screen and (min-width: 1200px) {
  .landing-intro {
    font-size: 32px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 768px) {
    .landing-intro {
        font-size: 22px;
        margin-bottom: 0;
        padding: 10px;
    }
}

.landing-buttons  {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 1200px;
    flex: 0 1 auto;
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .landing-buttons {
        flex-direction: column;
        justify-content: flex-start;
    }
}

.landing-button {
    padding: 10px 32px;
}

@media only screen and (max-width: 768px) {
    .landing-button {
        flex: 0 1 auto;
        padding: 5px 10px;
        margin: 5px;
    }
}

.landing-button:focus,
.landing-button:hover {
    background: linear-gradient(
        to left,
        #00C3F7,
        #05D1CC,
        #2AC288,
        #2AC288,
        #9BCD4D,
        #FBB800
    );
}

.icon {
    height: 48px;
    margin-right: 10px;
    vertical-align: top;
}

@media only screen and (max-width: 768px) {
    .icon {
        height: 50px;
    }
}

.landing-button__text {
    color: #454545;
    font-size: 30px;
    font-weight: 400;
}

.landing-button:focus .landing-button__text,
.landing-button:hover .landing-button__text {
    color: #FFFFFF;
}

/* We only have this many breakpoints for the landing, because it is very special */
@media only screen and (min-width: 1200px) {
  .landing-button__text {
      font-size: 36px;
  }
}

.landing-button__subheader {
    color: #454545;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 24px;
    margin: 0;
    font-style: italic;
}

@media only screen and (max-width: 768px) {
    .landing-button__subheader {
        font-size: 18px;
        margin: -5px -12px;
    }
}

.landing-text {
  flex: 1 1 auto;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 18px;
  margin: 0 auto;
  max-width: 1000px;
  padding: 20px;
  text-align: left;
}

@media only screen and (max-width: 768px) {
  .landing-text {
    background-color: #FFFFFF88;
    padding: 10px;
  }
}

.button_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* left sidebar / console */
#console {
  background-color: #FFF;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.0), 15%,
    rgba(255, 255, 255, 1.0) 40%
  );
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  min-height: 100%;
  margin: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 300px;
}

/* both ends of the gradient, but lighter */
#console.energy {
  background-color: #f8c570;
}

#console.power {
  background-color: #00C3F7;
}

/* show only the appropriate legend text and filters */
#console.energy .legend-header-container--power,
#console.power .legend-header-container--energy,
#console.energy .toggle-ui--managed  {
  display: none;
}

#console.energy .legend-header-container--energy,
#console.power .legend-header-container--power,
#console.power .toggle-ui--managed {
  display: block;
}

@media only screen and (max-width: 768px) {
  #console {
    min-height: unset;
    height: 400px;
    transition: height 0.5s ease, background 1s ease-in;
    width: 100%;
  }

  #console.collapsed {
    background-color: #9BCD4D;
    background-image: none;
    height: 40px;
    overflow-y: hidden;
  }

  #console.collapsed .console-content,
  #console.collapsed .map_view_selector {
    display: none;
  }
}

.action_button--share {
  color: #000000;
  flex: 1 1 auto;
  font-size: 20px;
  font-weight: 600;
  padding: 5px 20px 0;
  text-align: left;
}

.action_button--share:focus, .action_button--share:hover {
  color: #FFFFFF;
  background: linear-gradient(to right,
    #00C3F7,
    #05D1CC,
    #2AC288,
    #9BCD4D,
    #FBB800
   );
}

@media only screen and (max-width: 768px) {
  .action_button--share {
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    margin: 10px;
  }
}

.icon--share {
  display: inline;
  vertical-align: middle;
}

#console.energy .action_button--share {
  background-color: #FBB800;
}

#console.power .action_button--share {
  background-color: #00C3F7;
}

.action_button--success {
  background-color: #9BCD4D;
  color: #202020;
}

.console-content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin: 10px 40px;
}

@media only screen and (max-width: 768px) {
  .console-content {
    justify-content: space-between;
    padding: 0 5px;
    overflow: hidden;
  }
}

.map_view_selector {
  display: flex;
  justify-content: space-around;
  padding-bottom: 10px;
}

#console.energy .map_view_selector {
  background-color: #FBB800;
}

#console.power .map_view_selector {
  background-color: #00C3F7;
}

.map_view_selector path {
  fill: #000000;
}

.view_selector {
  background-color: transparent;
  border: none;
  cursor: pointer;
  flex: 1 1 auto;
  font-family: Jost,Arial,Sans-serif;
  font-size: 20px;
  font-weight: 300;
  padding: 10px;
  text-align: center;
}

#console.energy .view_selector--power,
#console.power .view_selector--energy {
  background-color: #dadad5;
}

/* fancy power / energy icon styles and rollover effects */
.view_selector__icon {
  height: 32px;
  margin: 5px 0;
  vertical-align: middle;
}

#console.energy .view_selector--power,
#console.power .view_selector--energy {
  background-color: #dadad5;
}

/* icon for current view */
#console.power .power-icon,
#console.energy .energy-icon {
  fill: #FFFFFF;
}

/* icon for the other view */
#console.energy .power-icon,
#console.power .energy-icon {
  fill: #505050;
}

/* rollover effects */
#console.energy .view_selector--power:hover #power1,
#console.energy .view_selector--power:focus #power1 {
  fill: url('#powerGradient1');
  stroke: none;
}

#console.energy .view_selector--power:hover #power2,
#console.energy .view_selector--power:focus #power2 {
  fill:url('#powerGradient2');
  stroke:none;
}

#console.power .view_selector--energy:hover #energy1,
#console.power .view_selector--energy:focus #energy1 {
  fill: url('#energyGradient1');
  stroke: none;
}

#console.power .view_selector--energy:hover #energy2,
#console.power .view_selector--energy:focus #energy2 {
  fill:url('#energyGradient2');
  stroke:none;
}

.control-header {
  text-align: left;
}

  @media only screen and (max-width: 768px) {
    .control-header {
      margin: 0;
      text-align: center
    }
}

.aside {
  font-size: 15px;
  font-weight: 400;
}

.header {
  color: #000;
  font-family: Jost,Arial,Sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .header {
    font-size: 16px;
    line-height: unset;
  }
}

.subheader {
  font-family: Jost,Arial,Sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

@media only screen and (max-width: 768px) {
  .subheader {
    text-align: right;
  }
}

.header.disabled {
  color: #999;
}

/* expandable / accordion buttons and links that look the same */
.header-button, .about-contact {
  border: none;
  border-bottom: 1px solid #FFF;
  font-family: Jost,Arial,Sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  padding: 10px;
  text-align: left;
}

/* make sure there is a focus style for keyboard accessibility */
[data-expandable]:focus {
  outline: 1px solid #FBB800;
  outline-offset: 1px;
}

/* the arrow using pseudo-content and unicode */
[data-expandable][aria-expanded="false"]::after {
  content: '\25b8\0020';
  margin-left: 6px;
}
/* the arrow for the expanded state using pseudo-content and unicode */
[data-expandable][aria-expanded="true"]::after {
  content: '\25bE\0020';
  margin-left: 6px;
}

.expandable {
  margin: 0 10px;
}

/* pair visual appearance with accessible state  */
.expandable[aria-hidden="true"] {
  display: none;
}

.checkbox_container {
  text-align: left;
}

@media only screen and (max-width: 768px) {
  .checkbox_container {
    margin-top: -12px;
    text-align: center;
  }
}

.secondary {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 10px;
  font-family: Jost,Arial,Sans-serif;
}

/* the legend */
.colors {
	 height: 24px;
   margin: 0 10px;
}

.legend-header-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#legend .header {
  margin-bottom: 0;
}

@media only screen and (max-width: 768px) {
  .legend-header-container {
    flex-direction: row;
    justify-content: space-between;
  }
}

.legend {
  font-size: 20px;
  line-height: 25px;
  flex-direction: column;
  font-family: Jost,Arial,Sans-serif;
}

.hex-legend {
  margin-bottom: 25px;
}

@media only screen and (max-width: 768px) {
  .hex-legend {
    margin-bottom: 20px;
  }
}

.legend .labels {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0 -5px;
  padding: 0;
  width: 110%;
}

@media only screen and (max-width: 768px) {
  .legend .labels {
    width: 100%;
  }
}

.legend .label {
  flex: 0 0 10%;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  text-align: center;
}

.dot {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}

.shape {
  height: 16px;
  width: 16px;
  border: 1px solid black;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}

.level2 .dot, .parking .dot {
  background-color: #202020;
}

.dc .dot {
  background-color: #CC00F7;
}

.justice40-tract .shape {
  background-color: rgba(0, 0, 0, 0.5);
}

.explanation, .ev_label, .explanation--toggle {
  font-family: Jost,Arial,Sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin: 0 0 10px 10px;
}

@media only screen and (max-width: 768px) {
  .explanation {
    text-align: center;
    margin: 0 0 10px 0;
  }
}

/* long haul / managed toggle controls */
.toggle-ui {
  margin-top: 10px;
}
.toggle-text {
  font-size: 12px;
  line-height: 24px;
  vertical-align: top;
  white-space: nowrap;
}

.explanation--toggle {
  margin-top: -10px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  vertical-align: top;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.filter-toggle--active + .toggle-slider {
  background-color: #2AC288;
}

input:focus + .toggle-slider {
  box-shadow: 0 0 1px #2AC288;
}

.filter-toggle--active + .toggle-slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.toggle-slider.round {
  border-radius: 20px;
}

.toggle-slider.round:before {
  border-radius: 50%;
}

.filter-icon__container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.filter-icon {
  background: transparent;
  border: none;
  margin-top: -12px;
  width: 48px;
}

.managed-icon {
  fill: none;
  stroke: #58595b;
  stroke-miterlimit: 10;
  stroke-width: 4.31px;
}

.longhaul-icon, .filter-icon--active .managed-icon__arrow {
  fill: #58595b;
}

.managed-icon__arrow, .managed-icon__wide {
  fill: none;
  stroke: none;
  -webkit-transition: .4s;
  transition: .4s;
}

.filter-icon--active .longhaul-icon
{
  fill: #2AC288;
}

.managed-icon__orange {
  fill: #dadad5;
}

.filter-icon--active .managed-icon__orange {
  fill: #fbb040;
}

.filter-icon--active .managed-icon__wide {
  stroke: #58595b;
  fill: #58595b;
  stroke-width: 8.09px;
}

/* the slider */
.control_ui {
  margin-bottom: 30px
}

@media only screen and (max-width: 768px) {
  .control_ui {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 768px) {
  .layer_container {
    margin-top: 8px;
    text-align: center;
  }
}

.session {
  flex-direction: column;
  margin: 5px auto;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .session {
    margin: 10px auto;
  }
}

.slider_label {
  margin-bottom: 5px;
  text-align: left;
}

@media only screen and (max-width: 768px) {
  .slider_label {
    margin-bottom: 0;
  }
}

input#slider {
  accent-color: #505050;
  flex: 1 1 auto;
}

.slidercontrols {
  display: flex;
  margin: 10px 0;
  justify-content: space-around;
}

.slider_button {
  border: none;
  background-color: transparent;
	cursor: pointer;
  flex: 0 0 auto;
}

.slider_button:disabled {
  opacity: 50%;
  cursor: default;
}

.filter_container {
  border-bottom: none;
  border-left: none;
  border-right: none;  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 15px;
  padding: 0 5px;
}

@media only screen and (max-width: 768px) {
  .filter_container {
    flex-direction: row;
    margin-top: 5px;
  }
}

.mwh-filter-header {
  margin-bottom: 5px;
}

.mwh-filter-label {
  flex: 0 1 auto;
}

input.mwh-filter {
  max-width: 70px;
}

.legend_toggle {
  color: #000;
  margin: 10px;
}

.resources_list {
  list-style: none;
  margin: 0 10px;
  padding: 0;
}

.resource {
  margin: 5px 0;
}

.small-logo {
  background: #505050;
}

.style-logo {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-top: 10px;
  display: block;
}

.style-logo--small {
  max-height: 100px;
}

.dark {
  background-color: #505050;
  color: #FFF;
}

.console-logo {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .console-logo {
    width: unset;
  }
}

.console-logo--bottom {
  margin-bottom: -30px;
}

.footer {
  background: linear-gradient(
    to right,
    #00C3F7,
    #05D1CC,
    #2AC288,
    #9BCD4D,
    #FBB800
   );
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 0 0 auto;
}

.version-text {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: italic;
  font-size: 9px;
  color: #505050;
  line-height: 12px;
  margin: 5px;
}

.version-text a {
  font-size: 12px;
}

#toggle-style {
  cursor: pointer;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 2;
}

/* the map */
#map {
  flex: 1 1 auto;
  background-color: #d3d3d4;
}

/* scale bar and layer switcher customization */
.mapboxgl-ctrl-scale {
	background-color: hsla(0,0%,25%,.75);
  border: 2px solid #333;
  border-top: #333;
  box-sizing: border-box;
	font-family: "Jost", Arial, Helvetica, sans-serif;
	font-weight: 500;
	color: #efe9e9;
  font-size: 14px;
  padding: 0 5px;
  white-space: nowrap;
}

.mapboxgl-ctrl-bottom-right {
  /* so the search box goes over it when expanded on mobile */
  z-index: 1;
}


/* popups */
.mapboxgl-popup {
  z-index: 20;
}

.mapboxgl-popup-content {
  background-color: #FFF;
  color: #FFF;
  box-shadow: 0px 1px 4px 1px #333;
  padding: 0;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip
{
  border-top-color: #505050;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
  border-bottom-color: #505050;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #505050;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #505050;
}

.mapboxgl-popup-close-button {
  color: #505050;
  font-size: 20px;
  line-height: 16px;
  margin: 2px;
}

.mapboxgl-popup-close-button.white {
  color: #FFF;
}

.popup-body {
  background-color: #505050;
  padding: 10px 12px;
}

.popup-title {
  color: #505050;
  font-family: "Jost", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 700;
  padding: 16px;
  padding-right: 24px;
}

.popup-secondary-title {
  color: #FFFFFF;
  font-family: "Jost", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 700;
  padding-top: 5px;
}

/* how do you know your popup is complicated? WELL... */
.popup-tertiary-title {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 700;
}

.popup-datum {
  display: flex;
  justify-content: space-between;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin: 12px auto;
}

.popup-datum--hexinfo {
  justify-content: flex-end;
  margin: 18px auto 0;
}

.popup-datum--utilitylist {
  margin-top: 0;
}

.popup-link, .popup-link:focus {
  font-weight: 400;
  color: #FBB800;
}

.popup-link:hover {
  color: #9BCD4D;
}

.varname {
	font-weight: 700;
  margin-right: 5px;
}

.popup-table {
  border-collapse: collapse;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
  text-align: left;
  width: 100%;
}

.popup-table__header {
  border-block-end: 2px solid;
  font-size: 15px;
  font-weight: 700;
  padding: 0 5px;
}

.popup-table thead th:first-child {
  padding: 0;
}

.popup-table thead th:not(:first-child),
.popup-table td {
  text-align: right;
}

.popup-table td {
    padding-bottom: 5px;
}

/* a placeholder for hex content fetched from the load shapes api */
#hex-content, #hex-popup-content {
  min-height: 244px;
  min-width: 266px;
}

.hex-content-container {
  border-top: solid 1px #FFFFFF;
}

.hosting-source .attribute {
  max-width: 50%;
  text-align: right;
}

.utility-companies {
  list-style: none;
  margin-top: 0;
  padding-left: 12px;
}

.arrow-container {
  background-color: #dadad5;
  text-align: center;
}

  #console.collapsed .arrow-container{
    background-color: transparent;
  }

.arrow {
  background: transparent;
  border: solid #505050;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 10px;
  transition: background-color 0.5s ease-in;
}

.arrow--up {
  border: solid #FFFFFF;
  border-width: 0 3px 3px 0;
  margin-top: 14px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.arrow--down {
  margin-bottom: 14px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
