html, body {
  box-sizing: border-box;
  color: #505050;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

label, .level-label {
  font-weight: 500;
}

a {
  color: #298fd1;
  font-family: Jost, Arial, sans-serif;
  font-weight: 700;
  text-decoration: none;
}

a:focus, a:hover {
  color: #2ac288;
}

h2, h3 {
  margin-bottom: 10px;
  font-family: Jost, Arial, sans-serif;
  font-weight: 700;
  display: block;
}

h2 {
  color: #000;
  font-size: 18px;
  line-height: 20px;
}

h3 {
  color: #505050;
  font-size: 16px;
}

.hidden {
  display: none;
}

.caps {
  text-transform: uppercase;
  font-weight: 500;
}

.no-scroll {
  background-color: #505050;
  position: relative;
  overflow: hidden;
}

.hide-for-small {
  display: inherit;
}

@media only screen and (width <= 768px) {
  .hide-for-small {
    display: none !important;
  }
}

.show-for-small {
  display: none;
}

@media only screen and (width <= 768px) {
  .show-for-small {
    display: block;
  }
}

.action_button {
  color: #fff;
  cursor: pointer;
  background-color: #2ac288;
  border: none;
  border-radius: 6px;
  padding: 10px;
  font-family: Jost, Arial, sans-serif;
  font-size: 32px;
  font-weight: 300;
  box-shadow: 4px 4px 4px #666;
}

@media only screen and (width <= 768px) {
  .action_button {
    font-size: 16px;
  }
}

.action_button--map {
  box-shadow: none;
  color: #000;
  background-color: #fbb800cc;
  font-size: 14px;
  font-weight: 400;
}

.container {
  box-sizing: border-box;
  flex-direction: row-reverse;
  height: 100%;
  display: flex;
}

.no-scroll .container {
  opacity: .5;
}

@media only screen and (width <= 768px) {
  .container {
    flex-direction: column;
  }
}

p.desktop-text {
  color: #505050;
  font-family: Roboto Condensed, sans-serif;
  font-size: 14px;
}

.instructions_overlay {
  z-index: 9;
  background-position-y: 80%;
  background-size: cover;
  position: absolute;
  inset: 100px;
  overflow: auto;
  box-shadow: 0 0 10px #333;
}

@media only screen and (width <= 768px) {
  .instructions_overlay {
    inset: 20px;
  }
}

.landing-header {
  background: linear-gradient(#fff0, 15%, #fff 80%), linear-gradient(to right, #00c3f7cc, #05d1cccc, #2ac288cc, #9bcd4dcc, #fbb800cc);
  justify-content: space-between;
  padding: 20px;
  display: flex;
}

@media only screen and (width <= 768px) {
  .landing-header {
    padding: 0;
  }
}

.header-item {
  text-align: left;
  padding: 20px;
}

@media only screen and (width <= 768px) {
  .header-item {
    margin: 0 auto;
  }
}

.landing-logo {
  width: 100%;
}

.landing-welcome {
  color: #ccc;
  font-family: Jost, Arial, sans-serif;
  font-size: 92px;
  font-weight: 300;
}

@media only screen and (width >= 1000px) {
  .landing-welcome {
    margin-top: -26px;
    margin-right: 60px;
    font-size: 92px;
  }
}

@media only screen and (width <= 999px) {
  .landing-welcome {
    margin-top: -15px;
    margin-right: 30px;
    font-size: 48px;
  }
}

.landing-container {
  text-align: center;
  background-image: url("index_background.1c5f5c34.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  display: flex;
  overflow-y: auto;
}

.landing-intro {
  flex: 0 auto;
  max-width: 1148px;
  margin: 0 auto 20px;
  padding: 0 20px;
  font-family: Roboto Condensed, Arial, sans-serif;
  font-size: 24px;
  font-weight: 500;
}

@media only screen and (width >= 1200px) {
  .landing-intro {
    margin-bottom: 40px;
    font-size: 32px;
  }
}

@media only screen and (width <= 768px) {
  .landing-intro {
    margin-bottom: 0;
    padding: 10px;
    font-size: 22px;
  }
}

.landing-buttons {
  flex: 0 auto;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}

@media only screen and (width <= 768px) {
  .landing-buttons {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.landing-button {
  padding: 10px 32px;
}

@media only screen and (width <= 768px) {
  .landing-button {
    flex: 0 auto;
    margin: 5px;
    padding: 5px 10px;
  }
}

.landing-button:focus, .landing-button:hover {
  background: linear-gradient(to left, #00c3f7, #05d1cc, #2ac288, #2ac288, #9bcd4d, #fbb800);
}

.icon {
  vertical-align: top;
  height: 48px;
  margin-right: 10px;
}

@media only screen and (width <= 768px) {
  .icon {
    height: 50px;
  }
}

.landing-button__text {
  color: #454545;
  font-size: 30px;
  font-weight: 400;
}

.landing-button:focus .landing-button__text, .landing-button:hover .landing-button__text {
  color: #fff;
}

@media only screen and (width >= 1200px) {
  .landing-button__text {
    font-size: 36px;
  }
}

.landing-button__subheader {
  color: #454545;
  margin: 0;
  font-family: Roboto Condensed, sans-serif;
  font-size: 24px;
  font-style: italic;
}

@media only screen and (width <= 768px) {
  .landing-button__subheader {
    margin: -5px -12px;
    font-size: 18px;
  }
}

.landing-text {
  text-align: left;
  flex: auto;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: Roboto Condensed, sans-serif;
  font-size: 18px;
}

@media only screen and (width <= 768px) {
  .landing-text {
    background-color: #fff8;
    padding: 10px;
  }
}

.button_container {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

#console {
  background-color: #fff;
  background-image: linear-gradient(#fff0, 15%, #fff 40%);
  flex-direction: column;
  flex: none;
  width: 300px;
  min-height: 100%;
  margin: 0;
  display: flex;
  overflow: hidden auto;
}

#console.energy {
  background-color: #f8c570;
}

#console.power {
  background-color: #00c3f7;
}

#console.energy .legend-header-container--power, #console.power .legend-header-container--energy, #console.energy .toggle-ui--managed {
  display: none;
}

#console.energy .legend-header-container--energy, #console.power .legend-header-container--power, #console.power .toggle-ui--managed {
  display: block;
}

@media only screen and (width <= 768px) {
  #console {
    min-height: unset;
    width: 100%;
    height: 400px;
    transition: height .5s, background 1s ease-in;
  }

  #console.collapsed {
    background-color: #9bcd4d;
    background-image: none;
    height: 40px;
    overflow-y: hidden;
  }

  #console.collapsed .console-content, #console.collapsed .map_view_selector {
    display: none;
  }
}

.action_button--share {
  color: #000;
  text-align: left;
  flex: auto;
  padding: 5px 20px 0;
  font-size: 20px;
  font-weight: 600;
}

.action_button--share:focus, .action_button--share:hover {
  color: #fff;
  background: linear-gradient(to right, #00c3f7, #05d1cc, #2ac288, #9bcd4d, #fbb800);
}

@media only screen and (width <= 768px) {
  .action_button--share {
    text-align: center;
    margin: 10px;
    font-size: 16px;
    font-weight: 300;
  }
}

.icon--share {
  vertical-align: middle;
  display: inline;
}

#console.energy .action_button--share {
  background-color: #fbb800;
}

#console.power .action_button--share {
  background-color: #00c3f7;
}

.action_button--success {
  color: #202020;
  background-color: #9bcd4d;
}

.console-content {
  flex-direction: column;
  flex: 1 0 auto;
  margin: 10px 40px;
  display: flex;
}

@media only screen and (width <= 768px) {
  .console-content {
    justify-content: space-between;
    padding: 0 5px;
    overflow: hidden;
  }
}

.map_view_selector {
  justify-content: space-around;
  padding-bottom: 10px;
  display: flex;
}

#console.energy .map_view_selector {
  background-color: #fbb800;
}

#console.power .map_view_selector {
  background-color: #00c3f7;
}

.map_view_selector path {
  fill: #000;
}

.view_selector {
  cursor: pointer;
  text-align: center;
  background-color: #0000;
  border: none;
  flex: auto;
  padding: 10px;
  font-family: Jost, Arial, sans-serif;
  font-size: 20px;
  font-weight: 300;
}

.view_selector__icon {
  vertical-align: middle;
  height: 32px;
  margin: 5px 0;
}

#console.energy .view_selector--power, #console.power .view_selector--energy {
  background-color: #dadad5;
}

#console.power .power-icon, #console.energy .energy-icon {
  fill: #fff;
}

#console.energy .power-icon, #console.power .energy-icon {
  fill: #505050;
}

#console.energy .view_selector--power:hover #power1, #console.energy .view_selector--power:focus #power1 {
  fill: url("#powerGradient1");
  stroke: none;
}

#console.energy .view_selector--power:hover #power2, #console.energy .view_selector--power:focus #power2 {
  fill: url("#powerGradient2");
  stroke: none;
}

#console.power .view_selector--energy:hover #energy1, #console.power .view_selector--energy:focus #energy1 {
  fill: url("#energyGradient1");
  stroke: none;
}

#console.power .view_selector--energy:hover #energy2, #console.power .view_selector--energy:focus #energy2 {
  fill: url("#energyGradient2");
  stroke: none;
}

.control-header {
  text-align: left;
}

@media only screen and (width <= 768px) {
  .control-header {
    text-align: center;
    margin: 0;
  }
}

.aside {
  font-size: 15px;
  font-weight: 400;
}

.header {
  color: #000;
  margin-bottom: 10px;
  font-family: Jost, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

@media only screen and (width <= 768px) {
  .header {
    font-size: 16px;
    line-height: unset;
  }
}

.subheader {
  text-align: left;
  font-family: Jost, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

@media only screen and (width <= 768px) {
  .subheader {
    text-align: right;
  }
}

.header.disabled {
  color: #999;
}

.header-button, .about-contact {
  text-align: left;
  border: none;
  border-bottom: 1px solid #fff;
  margin: 0;
  padding: 10px;
  font-family: Jost, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
}

[data-expandable]:focus {
  outline-offset: 1px;
  outline: 1px solid #fbb800;
}

[data-expandable][aria-expanded="false"]:after {
  content: "▸ ";
  margin-left: 6px;
}

[data-expandable][aria-expanded="true"]:after {
  content: "▾ ";
  margin-left: 6px;
}

.expandable {
  margin: 0 10px;
}

.expandable[aria-hidden="true"] {
  display: none;
}

.checkbox_container {
  text-align: left;
}

@media only screen and (width <= 768px) {
  .checkbox_container {
    text-align: center;
    margin-top: -12px;
  }
}

.secondary {
  margin-bottom: 10px;
  font-family: Jost, Arial, sans-serif;
  font-size: 14px;
  line-height: 24px;
}

.colors {
  height: 24px;
  margin: 0 10px;
}

.legend-header-container {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

#legend .header {
  margin-bottom: 0;
}

@media only screen and (width <= 768px) {
  .legend-header-container {
    flex-direction: row;
    justify-content: space-between;
  }
}

.legend {
  flex-direction: column;
  font-family: Jost, Arial, sans-serif;
  font-size: 20px;
  line-height: 25px;
}

.hex-legend {
  margin-bottom: 25px;
}

@media only screen and (width <= 768px) {
  .hex-legend {
    margin-bottom: 20px;
  }
}

.legend .labels {
  justify-content: space-between;
  width: 110%;
  margin: 0 -5px;
  padding: 0;
  list-style: none;
  display: flex;
}

@media only screen and (width <= 768px) {
  .legend .labels {
    width: 100%;
  }
}

.legend .label {
  text-align: center;
  flex: 0 0 10%;
  font-family: Roboto Condensed, sans-serif;
  font-size: 14px;
}

.dot {
  vertical-align: middle;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  display: inline-block;
}

.shape {
  vertical-align: middle;
  border: 1px solid #000;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  display: inline-block;
}

.level2 .dot, .parking .dot {
  background-color: #202020;
}

.dc .dot {
  background-color: #cc00f7;
}

.justice40-tract .shape {
  background-color: #00000080;
}

.explanation, .ev_label, .explanation--toggle {
  margin: 0 0 10px 10px;
  font-family: Jost, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

@media only screen and (width <= 768px) {
  .explanation {
    text-align: center;
    margin: 0 0 10px;
  }
}

.toggle-ui {
  margin-top: 10px;
}

.toggle-text {
  vertical-align: top;
  white-space: nowrap;
  font-size: 12px;
  line-height: 24px;
}

.explanation--toggle {
  margin-top: -10px;
}

.switch {
  vertical-align: top;
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  cursor: pointer;
  background-color: #ccc;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.toggle-slider:before {
  content: "";
  background-color: #fff;
  width: 20px;
  height: 20px;
  transition: all .4s;
  position: absolute;
  bottom: 2px;
  left: 2px;
}

.filter-toggle--active + .toggle-slider {
  background-color: #2ac288;
}

input:focus + .toggle-slider {
  box-shadow: 0 0 1px #2ac288;
}

.filter-toggle--active + .toggle-slider:before {
  transform: translateX(16px);
}

.toggle-slider.round {
  border-radius: 20px;
}

.toggle-slider.round:before {
  border-radius: 50%;
}

.filter-icon__container {
  flex-direction: column;
  margin-bottom: 10px;
  display: flex;
}

.filter-icon {
  background: none;
  border: none;
  width: 48px;
  margin-top: -12px;
}

.managed-icon {
  fill: none;
  stroke: #58595b;
  stroke-miterlimit: 10;
  stroke-width: 4.31px;
}

.longhaul-icon, .filter-icon--active .managed-icon__arrow {
  fill: #58595b;
}

.managed-icon__arrow, .managed-icon__wide {
  fill: none;
  stroke: none;
  transition: all .4s;
}

.filter-icon--active .longhaul-icon {
  fill: #2ac288;
}

.managed-icon__orange {
  fill: #dadad5;
}

.filter-icon--active .managed-icon__orange {
  fill: #fbb040;
}

.filter-icon--active .managed-icon__wide {
  stroke: #58595b;
  fill: #58595b;
  stroke-width: 8.09px;
}

.control_ui {
  margin-bottom: 30px;
}

@media only screen and (width <= 768px) {
  .control_ui {
    margin-bottom: 8px;
  }

  .layer_container {
    text-align: center;
    margin-top: 8px;
  }
}

.session {
  text-align: center;
  flex-direction: column;
  margin: 5px auto;
}

@media only screen and (width <= 768px) {
  .session {
    margin: 10px auto;
  }
}

.slider_label {
  text-align: left;
  margin-bottom: 5px;
}

@media only screen and (width <= 768px) {
  .slider_label {
    margin-bottom: 0;
  }
}

input#slider {
  accent-color: #505050;
  flex: auto;
}

.slidercontrols {
  justify-content: space-around;
  margin: 10px 0;
  display: flex;
}

.slider_button {
  cursor: pointer;
  background-color: #0000;
  border: none;
  flex: none;
}

.slider_button:disabled {
  opacity: .5;
  cursor: default;
}

.filter_container {
  border-bottom: none;
  border-left: none;
  border-right: none;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 15px;
  padding: 0 5px;
  display: flex;
}

@media only screen and (width <= 768px) {
  .filter_container {
    flex-direction: row;
    margin-top: 5px;
  }
}

.mwh-filter-header {
  margin-bottom: 5px;
}

.mwh-filter-label {
  flex: 0 auto;
}

input.mwh-filter {
  max-width: 70px;
}

.legend_toggle {
  color: #000;
  margin: 10px;
}

.resources_list {
  margin: 0 10px;
  padding: 0;
  list-style: none;
}

.resource {
  margin: 5px 0;
}

.small-logo {
  background: #505050;
}

.style-logo {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  display: block;
}

.style-logo--small {
  max-height: 100px;
}

.dark {
  color: #fff;
  background-color: #505050;
}

.console-logo {
  width: 100%;
}

@media only screen and (width <= 768px) {
  .console-logo {
    width: unset;
  }
}

.console-logo--bottom {
  margin-bottom: -30px;
}

.footer {
  background: linear-gradient(to right, #00c3f7, #05d1cc, #2ac288, #9bcd4d, #fbb800);
  flex-direction: row;
  flex: none;
  justify-content: space-between;
  display: flex;
}

.version-text {
  color: #505050;
  margin: 5px;
  font-family: Roboto Condensed, sans-serif;
  font-size: 9px;
  font-style: italic;
  line-height: 12px;
}

.version-text a {
  font-size: 12px;
}

#toggle-style {
  cursor: pointer;
  z-index: 2;
  position: absolute;
  top: 10px;
  left: 10px;
}

#map {
  background-color: #d3d3d4;
  flex: auto;
}

.mapboxgl-ctrl-scale {
  box-sizing: border-box;
  color: #efe9e9;
  white-space: nowrap;
  background-color: #404040bf;
  border: 2px solid #333;
  border-top: #333;
  padding: 0 5px;
  font-family: Jost, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mapboxgl-ctrl-bottom-right {
  z-index: 1;
}

.mapboxgl-popup {
  z-index: 20;
}

.mapboxgl-popup-content {
  color: #fff;
  background-color: #fff;
  padding: 0;
  box-shadow: 0 1px 4px 1px #333;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip, .mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip, .mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #505050;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip, .mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip, .mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
  border-bottom-color: #505050;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #505050;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #505050;
}

.mapboxgl-popup-close-button {
  color: #505050;
  margin: 2px;
  font-size: 20px;
  line-height: 16px;
}

.mapboxgl-popup-close-button.white {
  color: #fff;
}

.popup-body {
  background-color: #505050;
  padding: 10px 12px;
}

.popup-title {
  color: #505050;
  padding: 16px 24px 16px 16px;
  font-family: Jost, Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.popup-secondary-title {
  color: #fff;
  padding-top: 5px;
  font-family: Jost, Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.popup-tertiary-title {
  font-family: Roboto Condensed, Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 700;
}

.popup-datum {
  justify-content: space-between;
  margin: 12px auto;
  font-family: Roboto Condensed, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  display: flex;
}

.popup-datum--hexinfo {
  justify-content: flex-end;
  margin: 18px auto 0;
}

.popup-datum--utilitylist {
  margin-top: 0;
}

.popup-link, .popup-link:focus {
  color: #fbb800;
  font-weight: 400;
}

.popup-link:hover {
  color: #9bcd4d;
}

.varname {
  margin-right: 5px;
  font-weight: 700;
}

.popup-table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
  font-family: Roboto Condensed, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.popup-table__header {
  border-block-end: 2px solid;
  padding: 0 5px;
  font-size: 15px;
  font-weight: 700;
}

.popup-table thead th:first-child {
  padding: 0;
}

.popup-table thead th:not(:first-child), .popup-table td {
  text-align: right;
}

.popup-table td {
  padding-bottom: 5px;
}

#hex-content, #hex-popup-content {
  min-width: 266px;
  min-height: 244px;
}

.hex-content-container {
  border-top: 1px solid #fff;
}

.hosting-source .attribute {
  text-align: right;
  max-width: 50%;
}

.utility-companies {
  margin-top: 0;
  padding-left: 12px;
  list-style: none;
}

.arrow-container {
  text-align: center;
  background-color: #dadad5;
}

#console.collapsed .arrow-container {
  background-color: #0000;
}

.arrow {
  background: none;
  border: 3px solid #505050;
  border-width: 0 3px 3px 0;
  padding: 10px;
  transition: background-color .5s ease-in;
  display: inline-block;
}

.arrow--up {
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  margin-top: 14px;
  transform: rotate(-135deg);
}

.arrow--down {
  margin-bottom: 14px;
  transform: rotate(45deg);
}
/*# sourceMappingURL=index.6875155e.css.map */
